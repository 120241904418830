















































































import { ref, computed } from '@nuxtjs/composition-api';
import { TypeBody, TypeOverline, BaseIcon, BaseButton } from '@bambeehr/pollen';
import { PayrollProductKey, PlanProductKey } from '@/hooks/usePricePlans';
import PlanTitle from '@/modules/SelfServiceRegistration/components/PlanTitle/PlanTitle.vue';
import GlowCard from '@/modules/SelfServiceRegistration/components/GlowCard.vue';
import { ProductKey } from '@/gql/generated';

enum TagTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export default {
  name: 'PricePlanSelector',
  components: {
    TypeBody,
    TypeOverline,
    PlanTitle,
    BaseIcon,
    BaseButton,
    GlowCard,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    planType: {
      type: String,
      default: PlanProductKey.DEDICATED,
    },
    currentPlanKey: {
      type: String,
      required: false,
    },
    planTag: {
      type: String,
      default: '',
    },
    planTagType: {
      type: String,
      default: TagTypes.PRIMARY,
    },
    disabled: {
      type: Boolean,
      default: false, // Disabled state is false by default
    },
    hideMore: {
      type: Boolean,
      default: false,
    },
    allExpanded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isExpanded = ref(false);
    const toggleLearnMore = () => {
      emit('learnMore', props.planType);
      isExpanded.value = !isExpanded.value;
    };

    const tagClasses = computed( () => {
        if (props.planTagType === TagTypes.PRIMARY) {
          return `bg-secondary-tint-4 text-[#003f3e]`
        } 
        
        if (props.planTagType === TagTypes.SECONDARY) {
          return `bg-[#F0CDF4] text-[#4e1976]`
        } 
        
        if (props.planTagType === TagTypes.TERTIARY) {
          return `bg-[#F0CDF4] text-[#4e1976]`
        } 

        return ''
      });

    const optionClass = computed(
      () => `${props.currentPlanKey || props.planType}-option`
    );

    // Values map to tailwind colors
    const glowColor = computed(() => {
      switch (props.currentPlanKey || props.planType) {
        case PlanProductKey.LITE:
          return 'lite';
        case PlanProductKey.DEDICATED:
          return 'dedicated';
        case PlanProductKey.ELITE:
          return 'elite';
        case ProductKey.PayrollBasic:
          return 'payrollBasic';
        case ProductKey.PayrollPremium:
          return 'payrollPremium';

        default:
          return 'dedicated';
      }
    });

    return {
      PlanProductKey,
      isExpanded,
      toggleLearnMore,
      glowColor,
      optionClass,
      tagClasses,
    };
  },
};
