<template>
  <div v-if="isPaymentInitialized">
    <form @submit.prevent="handlePayment">
      <TextInput
        v-model="paymentForm.cardHolderName"
        size="large"
        placeholder="Cardholder Name"
        class="mb-4"
        data-qa="payment-card-name"
        label="Cardholder Name"
      />
      <div class="flex">
        <TextInput
          v-model="paymentForm.billingAddress"
          size="large"
          class="mb-4 mr-4 w-3/4"
          data-qa="payment-billing-address"
          label="Billing Address"
          placeholder="123 Street"
          autocomplete="street-address"
        />
        <TextInput
          v-model="paymentForm.suite"
          size="large"
          class="mb-4 w-1/4"
          data-qa="payment-suite"
          label="Suite"
          placeholder="#"
        />
      </div>
      <div class="flex">
        <TextInput
          v-model="paymentForm.city"
          size="large"
          class="mb-4 mr-4 w-1/2"
          data-qa="payment-city"
          label="City"
          placeholder="City"
          autocomplete="address-level2"
        />
        <SelectInput
          v-model="paymentForm.state"
          size="large"
          :options="Object.values(UsStates)"
          class="mb-4 w-1/2"
          data-qa="payment-state"
          label="State"
          placeholder="State"
          autocomplete="address-level1"
          :show-reset="false"
        />
      </div>

      <SelectInput
        v-model="paymentForm.country"
        size="large"
        :options="Countries"
        class="mb-4"
        data-qa="payment-state"
        label="Country"
        placeholder="Country"
        autocomplete="country"
        :show-reset="false"
      />

      <label for="payment-card-field" class="font-bold mb-2 block">
        Credit Card Number
      </label>
      <Striper class="cc_number_card" :class="{ 'is-error': declined }">
        <div id="payment-card-field" class="px-4 w-full" />
      </Striper>

      <BaseBanner :show-close="false" icon="lock" variant="explainer">
        <TypeBody variant="small">
          Bambee <b><u>only</u></b> accepts credit cards. As an added security
          measure, debit and pre-paid cards are not accepted.
        </TypeBody>
      </BaseBanner>

      <BaseDivider class="my-4" />

      <BaseBanner
        v-if="errorMessage"
        variant="error"
        class="mb-2"
        @close="errorMessage = ''"
      >
        {{ errorMessage }}
      </BaseBanner>
      <BaseButton
        v-if="!hideBtn"
        class="mb-2 bg-purple-gradient"
        :post-icon="loading || paymentSuccess ? '' : 'arrowRight'"
        size="giant"
        variant="primary"
        block
        type="submit"
        :disabled="loading || paymentSuccess"
      >
        <span v-if="loading" class="flex items-center">
          Subscribing
          <AnnularThrobber size="sm" class="ml-2" />
        </span>
        <span v-else>{{ btnTitle }}</span>
      </BaseButton>
    </form>

    <div class="mt-6 flex flex-col gap-4">
      <TypeBody variant="small">
        By clicking "{{ btnTitle }}", you agree to our Terms & Conditions &
        Privacy Policy
      </TypeBody>
      <SecuredDisclaimer class="pb-0" />
    </div>
  </div>
</template>

<script>
import {
  BaseBanner,
  BaseButton,
  TextInput,
  BaseDivider,
  AnnularThrobber,
  TypeBody,
  SelectInput,
} from '@bambeehr/pollen';

import {
  computed,
  ref,
  onMounted,
  watchEffect,
  watch,
  reactive,
} from '@nuxtjs/composition-api';
import Cookie from 'js-cookie';

import SecuredDisclaimer from '@/components/SecuredDisclaimer/SecuredDisclaimer';
import Striper from '@/modules/SelfServiceRegistration/components/Striper.vue';
import usePricePlans from '@/hooks/usePricePlans';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import CookieNames from '@/constants/CookieNames';
import UsStates from '@/constants/UsStates';
import { Countries } from '@/constants/Countries';

export default {
  name: 'PaymentForm',

  components: {
    BaseBanner,
    BaseButton,
    TextInput,
    Striper,
    BaseDivider,
    AnnularThrobber,
    SecuredDisclaimer,
    TypeBody,
    SelectInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    btnTitle: {
      type: String,
      default: 'Start 30 Day Trial',
    },
    paymentSuccess: {
      type: Boolean,
      default: false,
    },
    hideBtn: {
      type: Boolean,
      default: false,
    },

    trySubmit: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { isLoading } = usePricePlans();
    const {
      state: registrationState,
      initialized: registrationStateInitialized,
    } = useRegistration();
    const companyId = computed(
      () =>
        registrationState[RegistrationStateNames.COMPANY_ID] ||
        Cookie.get(CookieNames.COMPANY_ID)
    );
    const employeeCount = computed(
      () => registrationState[RegistrationStateNames.NUM_OF_EMPLOYEES]
    );
    const paymentForm = reactive({
      cardHolderName: '',
      billingAddress: '',
      suite: '',
      city: '',
      state: '',
      country: 'US',
    });
    const fullName = computed(
      () => registrationState[RegistrationStateNames.FULL_NAME]
    );
    const dependenciesReady = computed(() => {
      return registrationStateInitialized.value && !isLoading.value;
    });
    const isPaymentInitialized = ref(false);

    // Stripe Stuff
    const stripe = ref(null);
    const cardElement = ref(null);
    const isProcessingPayment = ref(false);
    let intervalId;
    const declined = ref(false);
    const loadStripe = () => {
      if (window?.Stripe) {
        stripe.value = window.Stripe(process.env.STRIPE_PUBLIC);
        const element = stripe.value.elements();
        cardElement.value = element.create('card');
        cardElement.value.mount('#payment-card-field');
        clearInterval(intervalId);
      }
    };

    const handlePayment = async () => {
      isProcessingPayment.value = true;

      const { token, error } = await stripe.value.createToken(
        cardElement.value,
        {
          name: paymentForm.cardHolderName,
          cardHolderName: paymentForm.cardHolderName,
          address_line1: paymentForm.billingAddress,
          address_line2: paymentForm.suite,
          address_city: paymentForm.city,
          address_state: paymentForm.state,
          address_country: paymentForm.country,
        }
      );

      if (error) {
        declined.value = true;

        return false;
      }

      emit('submit', {
        token,
        cardHolderName: paymentForm.cardHolderName,
      });
    };

    onMounted(() => {
      intervalId = setInterval(loadStripe, 500);
    });

    watch(props, ({ trySubmit }) => {
      if (trySubmit) {
        handlePayment();
      }
    });

    // PLAYGROUND

    watchEffect(() => {
      // One time setup of data
      if (dependenciesReady.value && !isPaymentInitialized.value) {
        paymentForm.cardHolderName =
          registrationState[RegistrationStateNames.FULL_NAME];
        isPaymentInitialized.value = true;
      }
    });

    return {
      companyId,
      employeeCount,
      isPaymentInitialized,
      declined,
      isProcessingPayment,
      handlePayment,
      fullName,
      paymentForm,
      UsStates,
      Countries,
    };
  },

  head() {
    return {
      script: [{ src: 'https://js.stripe.com/v3/' }],
    };
  },
};
</script>
