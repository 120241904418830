















































































































































































































import {
  computed,
  ref,
  useContext,
  watch,
  onMounted,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  BaseButton,
  BaseDivider,
  ProgressStepper,
  TypeBody,
  TypeDisplay,
  TypeHeading,
  BaseIcon,
} from '@bambeehr/pollen';
import Cookie from 'js-cookie';
import CookieNames from '@/constants/CookieNames';
import PaymentProcessingLoader from '@/modules/SelfServiceRegistration/components/PaymentProcessingLoader.vue';
import Modal from '@/modules/SelfServiceRegistration/components/Modal.vue';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import PaymentForm from '@/modules/SelfServiceRegistration/components/PaymentForm.vue';
import CoreSubscriptionShoppingCart from '@/modules/SelfServiceRegistration/components/ShoppingCart/CoreSubscriptionShoppingCart.vue';
import SubscriptionBenefits from '@/modules/SelfServiceRegistration/components/SubscriptionBenefits/SubscriptionBenefits.vue';
import LayoutApplicationStyle from '@/modules/SelfServiceRegistration/components/LayoutApplicationStyle.vue';
import BaseCard from '@/components/BaseCard/BaseCard.vue';
import usePricePlans, { PlanProductKey } from '@/hooks/usePricePlans';
import usePrice from '@/hooks/usePrice';
import useTrials, { trialPrice } from '@/hooks/useTrials';
import { pay } from '@/services/Payment';
import Analytics from '@/services/Analytics';
import { celebrate } from '@/plugins/vueConfetti';
import { error as DDError } from '@/services/Datadog';
import TrialTimer from '@/modules/SelfServiceRegistration/components/TrialTimer/TrialTimer.vue';
import PricePlanSelector from '@/modules/SelfServiceRegistration/components/PricePlanSelector/PricePlanSelector.vue';
import PlanTitle from '@/modules/SelfServiceRegistration/components/PlanTitle/PlanTitle.vue';
import { format } from 'date-fns';
import DateFormat from '@/constants/DateFormat';
import { netlifyEnterTest } from '@/utils/netlifyTest';
import { createMarketingDTO } from '@/services/Registration';
import {
  QueryParamNames,
  PaymentPageExperiences,
} from '@/constants/QueryParams';
import { getQueryCookie } from '@/lib/cookie-helpers';
import { BillingPeriod } from '@/constants/Billing';

export default {
  name: 'PaymentPage',
  components: {
    BaseButton,
    BaseCard,
    BaseDivider,
    BaseIcon,
    LayoutApplicationStyle,
    Modal,
    PaymentForm,
    PaymentProcessingLoader,
    ProgressStepper,
    CoreSubscriptionShoppingCart,
    SubscriptionBenefits,
    TypeBody,
    TypeDisplay,
    TypeHeading,
    TrialTimer,
    PricePlanSelector,
    PlanTitle,
  },

  setup(_, { emit, root }) {
    /**
     * HOOKS
     */
    // @ts-ignore
    const { $axios } = useContext();
    const { state: registrationState, updateRegistration } = useRegistration();
    const {
      selectedPlan,
      selectedSetupFee,
      selectedTotalPaymentDue,
      setSelectedPlanById,
      setWaivedSetupFee,
      bambeeLitePlan,
      getAppropriateDedicatedPlanByTier,
    } = usePricePlans();
    const router = useRouter();

    const { priceState } = usePrice($axios);
    const { trialDuration, trialEndDate } = useTrials();
    const formattedTrialEndLong = computed(() =>
      format(trialEndDate.value, DateFormat.MMMM_DO_YYYYY)
    );

    const formattedChargePeriod = computed(() => {
      switch (selectedPlan.value?.billingPeriod) {
        case BillingPeriod.MONTH:
          return ``;
        case BillingPeriod.QUARTER:
          return ``;
        case BillingPeriod.HALF_YEAR:
          return ``;
        case BillingPeriod.YEAR:
          return `on an annual basis`;
        case BillingPeriod.TWO_YEARS:
          return `on a biennial basis`;
        case BillingPeriod.THREE_YEARS:
          return `on a triennial basis`;
        default:
          return ``;
      }
    });
    const formattedTrialEndShort = computed(() =>
      format(trialEndDate.value, DateFormat.MM_DD_YYYY)
    );
    const isProcessingPayment = ref(false);
    const isPaymentSuccessful = ref(false);
    const isAnimatingPaymentProcessing = ref(false);
    const errorMessage = ref('');

    onMounted(() => {
      netlifyEnterTest();

      const paymentExperience =
        getQueryCookie()?.[QueryParamNames.PAYMENT_PAGE_EXPERIENCE];
      if (paymentExperience === PaymentPageExperiences.WVPP) {
        router.push(`/checkout/wvpp`);
      }
    });

    /**
     * Set state for payment plans
     */

    const pageHeader = computed(() => {
      return `$${trialPrice} to try Bambee for ${trialDuration.value} days, <span class="italic">seriously</span>`;
    });

    const btnTitle = computed(() => {
      if (isPaymentSuccessful.value) {
        return 'Payment Successful';
      }

      return `Start ${trialDuration.value} Day Trial`;
    });

    const availableDedicatedPlanByTier = computed(() => {
      // @ts-ignore
      return getAppropriateDedicatedPlanByTier(
        (registrationState[RegistrationStateNames.TIER_NUMBER] || 0) as number
      );
    });

    const nextSteps = computed(() => {
      return [`Start Trial`, `Meet HR Team`, `HR Strategy`];
    });

    const companyId = computed(
      () => registrationState[RegistrationStateNames.COMPANY_ID]
    );

    const isReady = computed(() => {
      return selectedPlan.value && companyId.value;
    });

    const showModal = computed(() => {
      return (
        isProcessingPayment.value ||
        (isPaymentSuccessful.value && !isAnimatingPaymentProcessing.value)
      );
    });

    /**
     * Methods
     */

    const handleSubmitPay = async ({ token, cardHolderName }) => {
      isProcessingPayment.value = true;
      isAnimatingPaymentProcessing.value = true;
      isPaymentSuccessful.value = false;
      errorMessage.value = '';

      const payload = {
        token,
        plan: selectedPlan.value?.value,
        setupFee: selectedSetupFee.value || 0,
        trial: trialDuration.value || 0,
        hrmId: '',
        originUrl: window.location.href,
        marketing: createMarketingDTO(registrationState),
      };

      const amountPaidToday = trialDuration.value
        ? trialPrice
        : selectedTotalPaymentDue.value;

      Analytics.track('submitted-new-subscription-payment', {
        ...payload,
        amountPaidToday,
        token: null,
        cardType: token.card.funding,
      });

      const res = await pay(companyId.value, payload).catch((err) => {
        errorMessage.value =
          err.response.data.message?.message ||
          `Something went wrong! Please verify your payment information`;
        DDError('Error with payment', err);

        isProcessingPayment.value = false;

        Analytics.track('failed-new-subscription-payment', {
          ...payload,
          token: null,
          cardType: token.card.funding,
        });
      });

      // An Error Occurred, exit early
      if (!res) {
        isProcessingPayment.value = false;

        return;
      }

      const products = [
        {
          type: 'Bambee Lite',
          plan: payload.plan,
          quantity: 1,
          // @ts-ignore
          amount: selectedPlan.value?.monthlyPrice, // TODO: should this be the total amount?
        },
        {
          type: 'Setup Fee',
          plan: 'Setup Fee',
          quantity: 1,
          amount: payload.setupFee,
        },
      ];

      const timestamp = Date.now();
      // Random unique id to associate to the lead for Facebook tracking
      const leadID = Math.floor(timestamp * Math.random() * 10000000);

      Analytics.track(
        'lead_converted_origin',
        {
          companyId: res._id,
          products: unescape(JSON.stringify(products)),
          amount: amountPaidToday,
          fee: payload.setupFee,
          originalFee: priceState.value.originalSetupFee,
          type: 'self-signup',
          owner: res._owner._id,
          origin: 'GET/WWW',
          currency: 'USD',
          leadID,
          ...res,
        },
        {
          Salesforce: true,
        }
      );

      Analytics.track('acquired-credit-card', {
        ...payload,
        companyId: res._id,
        products: unescape(JSON.stringify(products)),
        amount: amountPaidToday,
        originalFee: priceState.value.originalSetupFee,
        type: 'self-signup',
        owner: res._owner._id,
        origin: 'GET/WWW',
        // @ts-ignore
        tierNumber: selectedPlan.value?.tierNumber,
        category: 'get',
        label: 'try-bambee-payment',
        name: cardHolderName,
        marketing: createMarketingDTO(registrationState),
        user: res.data, // There is no res.data, this is a bug
      });

      // Google tag specifically for trial conversions/signups
      //@ts-ignore
      if (window.gtag && trialDuration.value > 0) {
        //@ts-ignore
        window.gtag('event', 'conversion', {
          send_to: 'AW-799566356/YPazCPLn3ZwZEJTUof0C',
        });
      }

      isProcessingPayment.value = false;
      isPaymentSuccessful.value = true;
      // @ts-ignore
      celebrate(root.$confetti);

      emit('submit', {
        [RegistrationStateNames.COMPANY_CONVERTED_AT]: res.converted_at,
        [RegistrationStateNames.PURCHASED_PLAN_ID]: res.plan,
      });
    };

    const handleAnimationFinished = () => {
      isAnimatingPaymentProcessing.value = true;
    };

    const selectPlan = (planId) => {
      setSelectedPlanById(planId);
      updateRegistration({
        [RegistrationStateNames.PLAN_ID]: planId,
      });
    };

    /**
     * Watchers/Executions
     */

    setWaivedSetupFee(true);

    let unwatch = () => {};
    unwatch = watch(
      availableDedicatedPlanByTier,
      (n, o) => {
        if (n) {
          let planId = n.value;

          selectPlan(planId);

          unwatch();
        }
      },
      {
        immediate: true,
      }
    );

    return {
      nextSteps,
      handleSubmitPay,
      showModal,
      handleAnimationFinished,
      isProcessingPayment,
      errorMessage,
      isPaymentSuccessful,
      btnTitle,
      trialDuration,
      trialPrice,
      pageHeader,
      formattedTrialEndLong,
      formattedTrialEndShort,
      bambeeLitePlan,
      selectedPlan,
      isReady,
      formattedChargePeriod,
    };
  },
};
