export enum BillingPeriod {
  MONTH = 'month',
  QUARTER = 'quarter',
  HALF_YEAR = 'halfYear',
  NINE_MONTHS = 'nineMonths',
  YEAR = 'year',
  TWO_YEARS = 'twoYears',
  THREE_YEARS = 'threeYears',
}

export default BillingPeriod;
