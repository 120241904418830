var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',[_c('div',{staticClass:"px-8 sm:px-12 py-12"},[_c('nav',{staticClass:"grid gap-12 sm:grid-cols-2 lg:grid-cols-4 max-w-5xl mx-auto w-full"},_vm._l((_vm.items),function(ref){
var title = ref.title;
var links = ref.links;
return _c('div',{key:title},[_c('h3',{staticClass:"font-overline-large font-bold mb-6"},[_vm._v(_vm._s(title))]),_vm._v(" "),(links)?_c('ul',{staticClass:"font-body-text"},_vm._l((links),function(ref,i){
var link = ref.link;
var text = ref.text;
return _c('li',{key:link,class:{ 'mt-4': i }},[_c('a',{staticClass:"text-gray-2",attrs:{"href":link}},[_vm._v("\n              "+_vm._s(text)+"\n            ")])])}),0):_c('div',{staticClass:"bg-primary-tint-6 rounded-md px-4 py-6 text-center"},[_c('h3',{staticClass:"font-bold leading-tight mb-1 text-14 tracking-wide font-poppins"},[_vm._v("\n            Talk to an HR expert\n          ")]),_vm._v(" "),_c('a',{staticClass:"font-overline-large font-bold text-primary",attrs:{"href":"tel:18443919499"}},[_vm._v("\n            1-844-391-9499\n          ")]),_vm._v(" "),_c('div',{staticClass:"font-body-text font-medium mb-4 mt-1"},[_vm._v("\n            "+_vm._s(_vm.bambeeHours)+"\n          ")]),_vm._v(" "),_c('BaseButton',{attrs:{"tag":"a","href":"mailto:sales@bambee.com"}},[_vm._v("\n            Contact Us\n          ")])],1)])}),0)]),_vm._v(" "),_c('div',{staticClass:"bg-primary flex flex-col lg:flex-row font-body-caption font-semibold items-center justify-between pb-6 pt-5 lg:px-12 lg:py-3 xl:px-20 text-white"},[_c('ul',{staticClass:"flex mb-4 lg:mb-0"},_vm._l((_vm.socialLinks),function(ref,i){
var title = ref.title;
var icon = ref.icon;
var link = ref.link;
return _c('li',{key:title,class:{ 'ml-5': i }},[_c('a',{attrs:{"href":link}},[_c('FontAwesomeIcon',{attrs:{"icon":icon}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(title))])],1)])}),0),_vm._v(" "),_c('ul',{staticClass:"flex mb-4 lg:mb-0"},_vm._l((_vm.legalLinks),function(ref,i){
var text = ref.text;
var link = ref.link;
return _c('li',{key:text,class:{ 'ml-4': i }},[(link === 'Careers')?_c('a',{attrs:{"href":link}},[_vm._v(_vm._s(text))]):_c('nuxt-link',{attrs:{"to":link}},[_vm._v(_vm._s(text))])],1)}),0),_vm._v(" "),_c('div',{staticClass:"lg:order-first opacity-70"},[_vm._v("\n      Copyright © Bambee "+_vm._s(_vm.year)+". All rights reserved.\n    ")])]),_vm._v(" "),(_vm.showWVPPBanner)?_c('WVPPBanner',{on:{"close":function($event){_vm.showWVPPBanner = false}}}):_c('CompletePaymentBanner')],1)}
var staticRenderFns = []

export { render, staticRenderFns }