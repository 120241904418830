var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cursor-pointer",class:( _obj = {}, _obj[_vm.optionClass] = true, _obj['cursor-default selected-option'] =  _vm.selected, _obj['opacity-50 pointer-events-none'] =  _vm.disabled, _obj ),on:{"click":function($event){$event.preventDefault();!_vm.disabled && _vm.$emit('click')}}},[_c('GlowCard',{attrs:{"active":_vm.selected && !_vm.disabled,"color":_vm.glowColor,"content-classes":"p-4 sm:p-6 container-class"}},[_c('TypeBody',{staticClass:"flex gap-3 sm:gap-6",attrs:{"variant":"small","tag":"div"}},[_c('div',[_c('div',{staticClass:"selector h-5 w-5 sm:h-6 sm:w-6 rounded-full mt-0.5 transition-all duration-75",class:_vm.selected
              ? 'border-[5px] sm:border-[7px] selected'
              : 'border-2 border-gray-3'})]),_vm._v(" "),_c('div',{staticClass:"space-y-1.5 w-full"},[_c('div',{staticClass:"flex flex-col sm:flex-row justify-between"},[_c('header',{staticClass:"flex gap-1"},[_c('PlanTitle',{attrs:{"plan-type":_vm.planType}}),_vm._v(" "),(_vm.planTag)?_c('div',{staticClass:"px-2 py-0.5 self-center rounded-md font-medium text-11 bg-",class:_vm.tagClasses},[_vm._v(_vm._s(_vm.planTag))]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex sm:justify-end"},[_vm._t("tag")],2)]),_vm._v(" "),_c('main',[_c('div',{staticClass:"space-y-2"},[_c('div',[_vm._t("default")],2),_vm._v(" "),((_vm.$slots.expand || _vm.allExpanded) && !_vm.hideMore)?_c('div',{staticClass:"flex items-center gap-1 text-primary-tint-1 font-bold cursor-pointer"},[(!_vm.allExpanded)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleLearnMore.apply(null, arguments)}}},[(!_vm.isExpanded)?_c('span',[_vm._v("Learn More...")]):_c('span',[_vm._v("Hide Details")])]):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.$slots.expand || _vm.allExpanded)?_c('div',{staticClass:"overflow-hidden transition-all",class:{
              'h-0 mt-0': !_vm.isExpanded,
              'h-auto mt-4': _vm.isExpanded || _vm.allExpanded,
            }},[_vm._t("expand")],2):_vm._e()])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }