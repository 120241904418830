/* eslint-disable no-else-return */
import {
  computed,
  useRoute,
  useRouter,
  watch,
  ref,
} from '@nuxtjs/composition-api';

import {
  goToNextPage as goToNextPageUtil,
  goToPreviousPage,
} from '../utils/selfServiceRouting';
import useRegistration from './useRegistration';

import CompanySize from '../components/screens/CompanySize';
import CompanyName from '../components/screens/CompanyName';
import ContactInfo from '../components/screens/ContactInfo';
import Industry from '../components/screens/Industry';
import ZipCode from '../components/screens/ZipCode';
import WhatBroughtYou from '../components/screens/WhatBroughtYou';
import Payment from '../components/screens/PaymentPage';
import PlanSelectionPage from '../components/screens/PlanSelectionPage';
import BillingCycleSelectionPage from '../components/screens/BillingCycleSelectionPage';
import ACHConfirmationPage from '../components/screens/ACHConfirmationPage.vue';
import MonthlyConfirmationPage from '../components/screens/MonthlyConfirmationPage.vue';
import PayrollSelectionPage from '../components/screens/PayrollSelectionPage.vue';

import SelfServicePages, {
  SelfServicePageSlugs,
  tiersThatGetSentToSelfService,
} from '@/modules/SelfServiceRegistration/constants/SelfServicePages.js';
import usePricePlans from '@/hooks/usePricePlans';

const INDEX_OF_SCHEDULE_CALL_PAGE = 4;

const useSelfService = () => {
  const route = useRoute();
  const router = useRouter();
  const { selectedPlan } = usePricePlans();
  const { state } = useRegistration();
  const isTierZero = computed(() => state.numOfEmployees === '0-0');

  const isSelfService = computed(() => {
    return tiersThatGetSentToSelfService.includes(
      selectedPlan.value?.tierNumber
    );
  });

  const hasValidateToken = computed(() => state.hasValidToken);

  const currentPageIndex = computed(() => {
    return SelfServicePages.findIndex(({ slug }) => {
      return route.value.params.slug === slug;
    });
  });

  const currentPageSlug = computed(() => {
    const page = SelfServicePages.find(
      ({ slug }) => route.value.params.slug === slug
    );

    return page ? page.slug : route.value.params.slug;
  });

  const pageComponent = computed(() => {
    switch (currentPageSlug?.value) {
      case SelfServicePageSlugs.WHAT_BROUGHT_YOU:
        return WhatBroughtYou;
      case SelfServicePageSlugs.COMPANY_SIZE:
        return CompanySize;
      case SelfServicePageSlugs.COMPANY_NAME:
        return CompanyName;
      case SelfServicePageSlugs.ZIP_CODE:
        return ZipCode;
      case SelfServicePageSlugs.INDUSTRY:
        return Industry;
      case SelfServicePageSlugs.CONTACT_INFO:
        return ContactInfo;
      case SelfServicePageSlugs.PAYMENT:
        return Payment;
      case SelfServicePageSlugs.PLAN_SELECTION:
        return PlanSelectionPage;
      case SelfServicePageSlugs.BILLING_SELECTION:
        return BillingCycleSelectionPage;
      case SelfServicePageSlugs.MONTHLY_CONFIRMATION:
        return MonthlyConfirmationPage;
      case SelfServicePageSlugs.ACH_CONFIRMATION:
        return ACHConfirmationPage;
      case SelfServicePageSlugs.PAYROLL_SELECTION:
        return PayrollSelectionPage;
      default:
        return WhatBroughtYou;
    }
  });

  const showBackButton = computed(() => {
    return (
      currentPageIndex.value > 0 &&
      currentPageIndex.value !== INDEX_OF_SCHEDULE_CALL_PAGE
    );
  });

  const goToNextPage = () => {
    return goToNextPageUtil(currentPageIndex.value, router, null);
  };

  return {
    currentPageIndex,
    currentPageSlug,
    goToNextPage,
    goToPreviousPage: () => goToPreviousPage(currentPageIndex.value, router),
    pageComponent,
    showBackButton,
    isSelfService,
    isTierZero,
    hasValidateToken,
  };
};

export default useSelfService;
