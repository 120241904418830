import {
  computed,
  ref,
  readonly,
  watch,
  ComputedRef,
} from '@nuxtjs/composition-api';
import capitalize from 'lodash/capitalize';
import PricePlans from '@/services/PricePlans';
import staticPlans from '@/plans.json';
import { BillingPeriod } from '@/constants/Billing';
import Analytics from '@/services/Analytics';

const tiersWithDedicatedMonthlyDefault = [0, 1, 2]; // Currently all else should default to annual

export enum PlanProductKey {
  LITE = 'LITE',
  DEDICATED = 'CORE',
  ELITE = 'ELITE',
}

export enum PayrollProductKey {
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
}

type PlanDescription = {
  title: string;
  header: string;
  description: string;
  priceCopy: string;
  features: string[];
};

type IPlan = {
  productKey: PlanProductKey;
  partner: string;
  version: string;
  status: string;
  annual: boolean;
  tierNumber: number;
  name: string;
  value: string;
  priceId: string;
  employeeSizeMapping: string;
  minSize: number;
  maxSize: number;
  pause: boolean;
  chatOnly: boolean;
  internal: boolean;
  regulated: boolean;
  price: number;
  monthlyPrice: number;
  setupFee: number;
  setupFeeDiscount: number;
  billingPeriod: string;
  volumeBased: boolean;
  collectTax: boolean;
  tags?: any[];
};

const isLoading = ref(false);
const allPlans = ref([] as IPlan[]);

const preparePlan = (plan) => ({
  employeeSizeName:
    capitalize(plan.employeeSizeMapping?.replace('-', ' - ')) || '',
  ...plan,
});

const fetchPlans = async (params = {}) => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;

  const plans = await PricePlans.fetchPlans(params);

  allPlans.value = plans
    .map(preparePlan)
    .filter((plan) => plan.employeeSizeMapping !== 'franchise');
  isLoading.value = false;
};

/**
 * COMPUTED
 */

const activePlans = computed(() =>
  allPlans.value.filter(({ status }) => status === 'active')
);

const activeDedicatedPlans = computed(() => {
  const plans = activePlans.value.filter(
    (plan: any) => plan.productKey === PlanProductKey.DEDICATED
  );

  return plans;
});
const activeDedicatedMonthlyPlans = computed(() => {
  const plans = activeDedicatedPlans.value.filter(
    (plan: any) => plan.billingPeriod === BillingPeriod.MONTH
  );

  return plans;
});

const activeElitePlans = computed(() =>
  activePlans.value.filter(
    (plan: any) => plan.productKey === PlanProductKey.ELITE
  )
);

const bambeeLitePlans = computed(() =>
  allPlans.value.filter((plan: any) => plan.productKey === PlanProductKey.LITE)
);

const activeLitePlans = computed(() =>
  activePlans.value.filter(
    (plan: any) => plan.productKey === PlanProductKey.LITE
  )
);

// Currently set to always be monthly
const bambeeLitePlan = computed(() =>
  activeLitePlans.value.find(
    (plan: any) => plan.billingPeriod === BillingPeriod.MONTH
  )
);

const planTiers = computed(() =>
  activePlans.value
    .filter(({ billingPeriod }) => billingPeriod === BillingPeriod.MONTH)
    // @ts-ignore
    .sort((a, b) => a.maxSize - b.maxSize)
);

const annualPlans = computed(() =>
  activePlans.value
    .filter(({ billingPeriod }) => billingPeriod === BillingPeriod.YEAR)
    // @ts-ignore
    .sort((a, b) => a.maxSize - b.maxSize)
);

const monthlyPlans = computed(() =>
  activePlans.value
    .filter(({ billingPeriod }) => billingPeriod === BillingPeriod.MONTH)
    // @ts-ignore
    .sort((a, b) => a.maxSize - b.maxSize)
);

/**
 * UTILITIES
 */

const getActivePlansByEmployeeCount = (numOfEmployees) =>
  activePlans.value.filter(
    (plan) => plan.employeeSizeMapping === numOfEmployees
  );

const getAnnualPlanByEmployeeRange = (numOfEmployees) =>
  getActivePlansByEmployeeCount(numOfEmployees).find(
    (p) => p?.billingPeriod === BillingPeriod.YEAR
  );

const getAppropriateDedicatedPlanByTier = (tierNumber = 0) => {
  let billingPeriod: string = BillingPeriod.YEAR;

  if (tiersWithDedicatedMonthlyDefault.includes(tierNumber)) {
    billingPeriod = BillingPeriod.MONTH;
  }

  // @ts-ignore
  return activeDedicatedPlans.value.find(
    (p) => p?.billingPeriod === billingPeriod && p?.tierNumber === tierNumber
  );
};

const getAppropriatePlanByEmployeeRange = (numOfEmployees) => {
  const activePlansByCount = getActivePlansByEmployeeCount(numOfEmployees);
  // @ts-ignore
  const activeTier = activePlansByCount[0]?.tierNumber;

  return getAppropriateDedicatedPlanByTier(activeTier);
};

const getPlanById = (id = '') => {
  return allPlans.value.find((plan) => plan.value === id);
};

const getEmployeeRangeByTier = (tier: number): string => {
  // @ts-ignore
  return (
    activePlans.value.find((p) => p.tierNumber === tier)?.employeeSizeMapping ||
    ''
  );
};

const calculateSetupFeeWithDiscount = (setupFee, discount) => {
  return setupFee - setupFee * discount;
};

/**
 * STATE
 */

const selectedPlanId = ref('');
const waivedSetupFee = ref(false);
const dollarTrial = ref(true); // TODO: Switch to hasTrial
const selectedPlan = computed(() => getPlanById(selectedPlanId.value));

const selectedSetupFee = computed(() => {
  const setupFee =
    waivedSetupFee.value || dollarTrial.value
      ? 0
      : // @ts-ignore
        selectedPlan.value?.setupFee;

  return calculateSetupFeeWithDiscount(
    setupFee,
    // @ts-ignore
    selectedPlan.value?.setupFeeDiscount
  );
});

const selectedTotalSubscriptionDue = computed(() => {
  // @ts-ignore
  const monthlyPrice = selectedPlan.value?.monthlyPrice || 0;

  return selectedPlan.value?.annual ? monthlyPrice * 12 : monthlyPrice;
});

const selectedTotalPaymentDue = computed(() => {
  return selectedTotalSubscriptionDue.value + selectedSetupFee.value;
});

const setSelectedPlanById = (id) => {
  if (selectedPlanId.value !== id) {
    selectedPlanId.value = id;
  }
};

const removeSelectedPlan = () => {
  selectedPlanId.value = '';
};

const setWaivedSetupFee = (waived = false) => {
  waivedSetupFee.value = waived;
};

const currentProduct = computed(() => selectedPlan.value?.productKey);

const currentTier = computed(() => selectedPlan.value?.tierNumber);

// get all plans whose product key matches the currentProduct.value so the user can select which billing interval they want
const getPlansByProduct = (productKey: PlanProductKey) =>
  activePlans.value.filter(
    (plan) =>
      plan.productKey === productKey && plan.tierNumber === currentTier.value
  );

const availablePlansByProduct: ComputedRef<IPlan[]> = computed(() => {
  // @ts-ignore
  return getPlansByProduct(currentProduct.value);
});

const setSelectedPlanByBillingInterval = (billingPeriod: BillingPeriod) => {
  const plan = selectedPlan.value;
  if (plan && plan.billingPeriod !== billingPeriod) {
    const planByBillingPeriod = availablePlansByProduct.value.find(
      (p) => p.billingPeriod === billingPeriod
    );

    if (planByBillingPeriod) {
      selectedPlanId.value = planByBillingPeriod.value;
    }
  }
};

const isReady = computed(() => {
  return !isLoading.value && !!activePlans.value.length;
});

const isYearlyT3PlusGroup = ref<boolean | null>(null);

// On initial load, set the isYearlyT3PlusGroup
watch(
  selectedPlan,
  (plan) => {
    if (isYearlyT3PlusGroup.value === null && plan) {
      isYearlyT3PlusGroup.value =
        selectedPlan.value?.billingPeriod === BillingPeriod.YEAR &&
        selectedPlan.value?.tierNumber >= 3;
    }
  },
  {
    immediate: true,
  }
);

const usePricePlans = (
  options = {
    force: false,
    params: {},
  }
) => {
  if (options.force || !allPlans.value.length) {
    fetchPlans(options.params || {});
  }

  return {
    isLoading: readonly(isLoading),
    isReady,
    plans: activePlans,
    dedicatedPlans: activeDedicatedPlans,
    elitePlans: activeElitePlans,
    monthlyPlans: activeDedicatedMonthlyPlans,
    annualPlans,
    tiers: planTiers,
    getAnnualPlanByEmployeeRange,
    getAppropriatePlanByEmployeeRange,
    getActivePlansByEmployeeCount,
    selectedPlanId,
    removeSelectedPlan,
    getAppropriateDedicatedPlanByTier,
    getEmployeeRangeByTier,
    getPlanById,
    selectedPlan,
    selectedSetupFee,
    selectedTotalSubscriptionDue,
    selectedTotalPaymentDue,
    setSelectedPlanById,
    setWaivedSetupFee,
    calculateSetupFeeWithDiscount,
    bambeeLitePlan,
    bambeeLitePlans,
    availablePlansByProduct,
    setSelectedPlanByBillingInterval,
    isYearlyT3PlusGroup,
  };
};

export default usePricePlans;
