





















import { computed } from '@nuxtjs/composition-api';
import { TypeDisplay, TypeHeading } from '@bambeehr/pollen';
import { PlanProductKey, PayrollProductKey } from '@/hooks/usePricePlans';
import { ProductKey } from '@/gql/generated';
import BillingPeriod from '@/constants/Billing';

export enum BillingTypes {
  ACH = 'ACH',
  CREDIT_CARD = 'CARD',
}

export default {
  name: 'PlanTitle',
  components: {
    TypeDisplay,
    TypeHeading,
  },
  props: {
    planType: {
      type: String,
      default: PlanProductKey.DEDICATED,
    },
  },
  setup(props) {
    const isBillingTerm = computed(() => {
      return [
        BillingPeriod.MONTH,
        BillingPeriod.QUARTER,
        BillingPeriod.HALF_YEAR,
        BillingPeriod.YEAR,
        BillingPeriod.TWO_YEARS,
        BillingPeriod.THREE_YEARS,
        BillingTypes.ACH,
        BillingTypes.CREDIT_CARD,
      ].includes(props.planType);
    });
    const label = computed(() => {
      switch (props.planType) {
        case PlanProductKey.LITE:
          return 'Lite';

        case PlanProductKey.ELITE:
          return 'Elite';

        case ProductKey.PayrollBasic:
          return 'Basic';

        case ProductKey.PayrollPremium:
          return 'Premium';

        case BillingPeriod.MONTH:
          return 'Monthly';

        case BillingPeriod.QUARTER:
          return '3-month';

        case BillingPeriod.HALF_YEAR:
          return '6-month';

        case BillingPeriod.YEAR:
          return '12-month';

        case BillingPeriod.TWO_YEARS:
          return '24-month';

        case BillingPeriod.THREE_YEARS:
          return '36-month';

        case BillingTypes.ACH:
          return 'ACH';

        case BillingTypes.CREDIT_CARD:
          return 'Credit Card';

        default:
          return 'Dedicated';
      }
    });
    const productType = computed(() => {
      // @ts-ignore
      const isPayroll = [
        ProductKey.PayrollBasic,
        ProductKey.PayrollPremium,
      ].includes(props.planType);
      if (isPayroll) {
        return 'Payroll';
      }

      if (isBillingTerm.value) {
        return label.value;
      }

      return 'Bambee';
    });

    return {
      PlanProductKey,
      label,
      productType,
      isBillingTerm,
    };
  },
};
