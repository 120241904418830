import { reactive, readonly, ref, watch } from '@nuxtjs/composition-api';
import { getFromLocalStorage, saveInLocalStorage } from '@/utils/localStorage';
import useLeadService from '@/modules/SelfServiceRegistration/hooks/useLeadService';
import Registration from '@/services/Registration';
import Cookie from 'js-cookie';
import CookieNames from '@/constants/CookieNames';
import { QueryParamNames } from '@/constants/QueryParams';
import usePricePlans from '@/hooks/usePricePlans';
import useResumeSession from './useResumeSession';

let instantiated = false;
const initialized = ref(false);
let getAppropriatePlanByEmployeeRange = () => {};
let getPlanById = () => {};
let getEmployeeRangeByTier = () => {};
let getLead = () => {};

const { resumeSession } = useResumeSession();

export const CALL_STATES = Object.freeze({
  CALL_CONFIRMED: 'call-confirmed',
  CALL_ME_NOW: 'call-me-now',
});

export const RegistrationStateNames = Object.freeze({
  HAS_VALID_TOKEN: 'hasValidToken',
  // Company Info
  COMPANY_ID: 'companyId',
  COMPANY_CREATED_AT: 'companyCreatedAt',
  COMPANY_CONVERTED_AT: 'companyConvertedAt',
  COMPANY_NAME: 'name',
  INDUSTRY: 'industry',

  // Plan Info
  BILLING_PLAN_ID: 'billingPlanId',
  PLAN_ID: 'plan',
  TIER_NUMBER: 'tierNumber',
  NUM_EMPLOYEES: 'numOfEmployees',
  NUM_EMPLOYEES_FREE_TEXT: 'employeeSizeFreeText',

  // User Info
  USER_ID: 'userId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  FULL_NAME: 'fullName',
  PHONE: 'phone',
  EMAIL: 'email',
  ZIP: 'zip',
  STATE: 'state',
  PASSWORD: 'password',

  // Marketing Info
  HOW_HEARD: 'howDidYouHear',
  STATION: 'station',
  FUNNEL: 'funnel',
  PARTNER_STACK_ID: 'partnerStackId',

  // Misc
  PAGE_REGISTERED_ON: 'pageRegisteredOn',
  SELF_SIGNUP: 'selfSignup',
  HRMID: 'hrmId',
  NEWLEAD: 'isNewLead',
  CALLTYPE: 'callType',
  CALLSTARTTIME: 'callStartTime',
  CALLENDTIME: 'callEndTime',
  WHAT_BROUGHT_YOU_TO_BAMBEE: 'what_brought_you_to_bambee',
  OFFER_CREATED_AT: 'offerCreatedAt',
  LEAD_DEVICE_TYPE: 'leadDeviceType',
  PAYMENT_PAGE_EXPERIENCE: 'checkoutExperience',

  PURCHASED_PLAN_ID: 'purchasedPlan',
  PAYMENT_METHOD: 'paymentMethod',
  BILLING_PERIOD: 'billingPeriod',
  PAYROLL_PLAN: 'payrollPlan',
});

const state = reactive({
  [RegistrationStateNames.HAS_VALID_TOKEN]: false,
  [RegistrationStateNames.COMPANY_ID]: '',
  [RegistrationStateNames.USER_ID]: '',
  [RegistrationStateNames.PAGE_REGISTERED_ON]: '',
  [RegistrationStateNames.FIRST_NAME]: '',
  [RegistrationStateNames.LAST_NAME]: '',
  [RegistrationStateNames.FULL_NAME]: '',
  [RegistrationStateNames.PHONE]: '',
  [RegistrationStateNames.EMAIL]: '',
  [RegistrationStateNames.COMPANY_NAME]: '',
  [RegistrationStateNames.INDUSTRY]: '',
  [RegistrationStateNames.NUM_EMPLOYEES]: '',
  [RegistrationStateNames.ZIP]: '',
  [RegistrationStateNames.PLAN_ID]: null,
  [RegistrationStateNames.TIER_NUMBER]: null,
  [RegistrationStateNames.HOW_HEARD]: '',
  [RegistrationStateNames.NUM_EMPLOYEES_FREE_TEXT]: '',
  [RegistrationStateNames.SELF_SIGNUP]: true,
  [RegistrationStateNames.STATE]: '',
  [RegistrationStateNames.HRMID]: '',
  [RegistrationStateNames.FUNNEL]: '',
  [RegistrationStateNames.STATION]: '',
  [RegistrationStateNames.NEWLEAD]: false,
  [RegistrationStateNames.CALLTYPE]: '',
  [RegistrationStateNames.CALLSTARTTIME]: '',
  [RegistrationStateNames.CALLENDTIME]: '',
  [RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE]: '',
  [RegistrationStateNames.COMPANY_CREATED_AT]: '',
  [RegistrationStateNames.COMPANY_CONVERTED_AT]: '',
  [RegistrationStateNames.OFFER_CREATED_AT]: '',
  [RegistrationStateNames.PASSWORD]: '',
  [RegistrationStateNames.LEAD_DEVICE_TYPE]: '',
  [RegistrationStateNames.PARTNER_STACK_ID]: '',
  [RegistrationStateNames.PAYMENT_PAGE_EXPERIENCE]: '',
  [RegistrationStateNames.BILLING_PLAN_ID]: '',
  [RegistrationStateNames.PURCHASED_PLAN_ID]: '',
  [RegistrationStateNames.PAYMENT_METHOD]: 'CARD',
  [RegistrationStateNames.BILLING_PERIOD]: '',
  [RegistrationStateNames.PAYROLL_PLAN]: '',
});

const LOCAL_STORAGE_REGISTRATION_KEY = 'reg_state';

const updateRegistration = (toSave = {}, saveToLocalStorage = true) => {
  Object.entries(toSave).forEach(([key, val]) => {
    if (!(key in state)) {
      return;
    }

    state[key] = val;

    if (key === RegistrationStateNames.NUM_EMPLOYEES) {
      const targetPlan = getAppropriatePlanByEmployeeRange(val);
      const planId = targetPlan?.value || null;
      const tierNumber = targetPlan?.tierNumber || null;
      state[RegistrationStateNames.PLAN_ID] = planId;
      state[RegistrationStateNames.TIER_NUMBER] = tierNumber;
    }

    if (key === RegistrationStateNames.PLAN_ID && val) {
      const plan = getPlanById(val);
      if (plan) {
        // Now account for lite plans that do not have tier number or employee sizing
        if (
          plan.tierNumber !== undefined ||
          plan.employeeSizeMapping !== undefined
        ) {
          state[RegistrationStateNames.TIER_NUMBER] = plan.tierNumber;
          state[RegistrationStateNames.NUM_EMPLOYEES] =
            plan.employeeSizeMapping;
        }
      } else {
        const brittleTierNumber = val.split('_t').at(-1) || '';
        if (brittleTierNumber) {
          const evenMoreBrittleTier = Number(brittleTierNumber);
          const eeRange = getEmployeeRangeByTier(evenMoreBrittleTier);
          state[RegistrationStateNames.TIER_NUMBER] = evenMoreBrittleTier;
          state[RegistrationStateNames.NUM_EMPLOYEES] = eeRange;
        }
      }
    }

    if (val && saveToLocalStorage) {
      saveInLocalStorage(LOCAL_STORAGE_REGISTRATION_KEY, JSON.stringify(state));
    }
  });
};

const hydrateFromCookies = () => {
  const cookieQueryStr = Cookie.get(CookieNames.QUERY_PARAMS) || '{}';
  const cookieQuery = JSON.parse(cookieQueryStr);
  const partnerStackIdCookie = Cookie.get(CookieNames.PARTNER_STACK_ID) || '';
  const funnelCookie = Cookie.get(CookieNames.FUNNEL) || '';
  const toUpdate = {
    [RegistrationStateNames.PAYMENT_PAGE_EXPERIENCE]:
      cookieQuery[QueryParamNames.PAYMENT_PAGE_EXPERIENCE] || '',
    [RegistrationStateNames.PARTNER_STACK_ID]: partnerStackIdCookie,
    [RegistrationStateNames.FUNNEL]: funnelCookie,
  };

  updateRegistration(toUpdate);
};

const hydrateFromLocalStorage = () => {
  const stateInStorage = getFromLocalStorage(LOCAL_STORAGE_REGISTRATION_KEY);
  const newState = (stateInStorage && JSON.parse(stateInStorage)) || {};
  updateRegistration(newState, false);
};

const hydrateFromAPI = async () => {
  const APIUserData = await getLead();
  let userData = APIUserData?.data;

  if (!userData) {
    // Attempt to resume from information in cookies
    userData = await resumeSession();

    if (!userData) {
      updateRegistration({
        [RegistrationStateNames.HAS_VALID_TOKEN]: false,
      });

      return;
    }
  }

  const {
    _company: {
      profile: companyProfile,
      name: companyName,
      plan: planId,
      id: companyId,
      marketing: companyMarketing,
      converted_at: companyConvertedAt,
    },
    _auth: auth,
    profile,
    _id,
  } = userData || {};

  const apiData = {
    [RegistrationStateNames.HAS_VALID_TOKEN]: true,
    [RegistrationStateNames.FIRST_NAME]: profile.first_name,
    [RegistrationStateNames.LAST_NAME]: profile.last_name,
    [RegistrationStateNames.FULL_NAME]: profile.full_name,
    [RegistrationStateNames.PHONE]: profile.phone,
    [RegistrationStateNames.EMAIL]: auth.email,
    [RegistrationStateNames.COMPANY_NAME]: companyName,
    [RegistrationStateNames.INDUSTRY]: companyProfile.industry,
    [RegistrationStateNames.ZIP]: companyProfile.zip,
    [RegistrationStateNames.STATE]: companyProfile.state,
    [RegistrationStateNames.PLAN_ID]: planId,
    [RegistrationStateNames.COMPANY_ID]: companyId,
    [RegistrationStateNames.USER_ID]: _id,
    [RegistrationStateNames.PURCHASED_PLAN_ID]: !!companyConvertedAt
      ? planId
      : '',
    [RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE]:
      companyMarketing?.howDidYouHear ||
      state[RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE] ||
      'n/a',
  };

  updateRegistration(apiData);
};

const hydrateProgress = async () => {
  hydrateFromLocalStorage();
  hydrateFromCookies();
  await hydrateFromAPI();
  initialized.value = true;
};

// Returns a promise that resolves to the user object
const register = async (toSave = {}) => {
  const user = await Registration.register(toSave).catch((error) => {
    console.error(error);
    throw new Error(error);
  });

  if (!user) {
    return;
  }

  updateRegistration({
    [RegistrationStateNames.HAS_VALID_TOKEN]: true,
    [RegistrationStateNames.COMPANY_ID]: user._company.id,
    [RegistrationStateNames.COMPANY_CREATED_AT]: user._company.created_at,
  });

  return user;
};

const useRegistration = () => {
  // For Singleton patterns, where we only want 1 source of truth
  if (!instantiated) {
    instantiated = true;
    const {
      getAppropriatePlanByEmployeeRange:
        getAppropriatePlanByEmployeeRangeOriginal,
      getPlanById: getPlanByIdOriginal,
      getEmployeeRangeByTier: getEmployeeRangeByTierOriginal,
      isReady: isPlansReady,
    } = usePricePlans();

    const { getLead: getLeadOriginal } = useLeadService();
    getLead = getLeadOriginal;

    getAppropriatePlanByEmployeeRange =
      getAppropriatePlanByEmployeeRangeOriginal;
    getPlanById = getPlanByIdOriginal;
    getEmployeeRangeByTier = getEmployeeRangeByTierOriginal;

    const unwatch = watch(
      isPlansReady,
      (n) => {
        if (n) {
          hydrateProgress();
          unwatch && unwatch();
        }
      },
      { immediate: true }
    );
  }

  return {
    state: readonly(state),
    initialized,
    updateRegistration,
    hydrateProgress,
    register,
  };
};

export default useRegistration;
