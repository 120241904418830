import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripe: Stripe;

export default async function getStripe() {
  if (stripe) {
    return stripe;
  }

  if (!process.env.STRIPE_PUBLIC) {
    throw new Error('Stripe public key not set');
  }

  const loaded = await loadStripe(process.env.STRIPE_PUBLIC);

  if (!loaded) {
    throw new Error('Failed to load stripe');
  }

  stripe = loaded;

  return loaded;
}
